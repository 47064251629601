.auth-wrapper {
    @apply h-full flex flex-col bg-auth;

    @include media-breakpoint-up('xl') {
        @apply flex-row;
    }

    &-aside {
        @apply w-full hidden flex-col justify-between py-10 pb-14 px-8 bg-auth-bg overflow-y-auto;
        background-size: cover;
        
        @include media-breakpoint-up('xl') {
            @apply w-2/6 flex;
        }

        @include media-breakpoint-up('xxl') {
            @apply pt-20 px-32;
        }

        img {
            @apply mb-16
        }

        h2 {
            @apply mb-4 text-lg font-light text-white;

            @include media-breakpoint-up('xxl') {
                @apply mb-16 text-3xl;
            }
        }

        p {
            @apply pl-4 font-light text-white border-l-2 border-white border-solid;
        }

        ul {
            @apply pl-4 border-l-2 border-white border-solid text-white text-sm font-light space-y-4;

            @include media-breakpoint-up('xxl') {
                @apply text-base;
            }
        }

        footer {
            @apply mt-auto text-sm font-light text-white;
        }
    }

    &-content {
        @apply w-full py-5 px-4;

        @include media-breakpoint-up('sm') {
            @apply px-14;
        }

        @include media-breakpoint-up('xl') {
            @apply w-4/6;
        }

        @include media-breakpoint-up('xxl') {
            @apply px-24 pt-10;
        }

        h1 {
            @apply mb-3 text-grey-800 text-lg font-light;

            @include media-breakpoint-up('sm') {
                @apply text-2xl;
            }

            @include media-breakpoint-up('xxl') {
                @apply mb-6 text-4xl;
            }
        }

        h4 {
            @apply mr-5 text-grey-600 text-right;
        }

        h6 {
            @apply mb-8 text-grey-800 text-base font-light;

            @include media-breakpoint-up('sm') {
                @apply text-lg;
            }

            @include media-breakpoint-up('xxl') {
                @apply mb-20;
            }
        }

        header {
            @apply flex flex-wrap justify-end items-center mb-6;

            @include media-breakpoint-up('xxl') {
                @apply mb-20;
            }
        }

        &__succes-ui {
            figure {
                @apply w-32 h-32 flex items-center justify-center mb-16 rounded-full bg-primary-500 svg-size-medium svg-color-white;
            }
        }
    }

    .btn-back-login {
        @apply self-start inline-flex items-center mt-10 text-primary-600;
    }
}