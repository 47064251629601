.btn {
    height: 2.125rem;
    padding: 0 1.5625rem;
    @apply inline-flex justify-center items-center py-3 rounded shadow-btn text-sm font-medium leading-4 tracking-wide;

    &:hover {
        @apply shadow-lg;
    }

    &:focus {
        @apply outline-none;
    }

    &-primary {
        @extend .btn;

        @apply bg-primary-500 text-white;
    }

    &-primary-outlined {
        @extend .btn;

        @apply border border-solid border-primary-500 text-primary-500;
    }

    &-primary-text {
        @extend .btn;

        @apply text-primary-500 shadow-none;
    }

    &-secondary {
        @extend .btn;

        @apply bg-primary-700 text-white;
    }

    &-secondary-outlined {
        @extend .btn;

        @apply border border-solid border-primary-700 text-primary-700;
    }

    &-secondary-text {
        @extend .btn;

        @apply text-primary-700 shadow-none;
    }

    &.md {
        height: 2.375rem;
        padding: 0 1.875rem;
        @apply py-3.5;
    }

    &.lg {
        height: 3.375rem;
        padding: 0 1.875rem;
        font-size: 1.1875rem;
        @apply py-5;
    }

    &-icon {
        height: 2.375rem;
        @extend .btn;

        @apply py-3 px-4 shadow-none;

        svg {
            @apply w-3.5 h-3.5;
        }

        &-primary {
            @extend .btn-icon;
            @apply bg-primary-500 shadow-btn;
        }

        &-primary-outlined {
            @extend .btn-icon;
            @apply border border-solid border-primary-500 shadow-btn;
        }

        &.lg {
            height: 3.125rem;
            @apply py-3 px-4;
        }
    }
}

.btn-kebab-child {
    @apply w-full flex items-center py-2 px-3 transition duration-150 ease-in-out svg-color-white;

    &:hover {
        @apply bg-primary-400;
    }
}