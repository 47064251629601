/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  max-height: initial !important;
}

.title-bordered {
  @apply relative mb-7;

  &:before,
  &:after {
    content: '';

    @apply absolute -bottom-3 left-0 border-b-2 border-solid rounded;
  }

  &:before {
    @apply w-20 border-primary-200;
  }

  &:after {
    @apply w-7 border-primary-600;
  }
}

.field-group {
  @apply relative mb-6;

  &-error {
    input {
      @apply border-red-700;
    }

    .react-select .react-select__control {
      @apply border-red-700;
    }

    .field-wrap .react-datepicker-wrapper .react-datepicker__input-container {
      input {
        @apply border-red-700;
      }
    }
  }
}

.field-label {
  @apply block text-sm mb-2 text-grey-700;

  @include media-breakpoint-up('xxl') {
    @apply text-base;
  }
}

.field-wrap {
  @apply flex relative bg-white;

  &.icon-end {
    input,
    select,
    textarea {
      @apply pr-14;
    }
  }

  &.icon-start {
    input,
    select,
    textarea {
      @apply pl-10;
    }
  }
}

.field {
  max-height: $fieldHeight;
  padding: $fieldPadding;

  @apply w-full text-sm text-black border border-primary-200 rounded bg-transparent placeholder-gray-300 z-1;

  &:focus {
    @apply ring-1 ring-primary-500 border-primary-500 outline-none;
  }

  &:disabled,
  &:read-only {
    @apply border-grey-400 bg-grey-100;
  }

  &-password {
    @extend .field;

    @apply pr-12;
  }

  &-phone {
    @extend .field;

    @apply pl-24;
  }

  &-otp {
    @extend .field;
    max-height: initial;

    @apply w-16 h-16 p-2 text-2xl mr-6 #{!important};

    @include media-breakpoint-down('xl') {
      @apply w-10 h-10 text-lg mr-2 #{!important};
    }
  }
}

.field-icon {
  @apply absolute top-1/2 -translate-y-1/2;

  svg {
    @apply w-5 h-5;

    * {
      &:not([stroke-width]) {
        &:not([fill='none']) {
          fill: rgba($color: $primaryColor, $alpha: 0.85);
        }
      }

      &[stroke-width] {
        stroke: rgba($color: $primaryColor, $alpha: 0.85);
      }
    }
  }
}

.date-time-picker {
  .react-datepicker-wrapper {
    @apply w-full;

    .react-datepicker__input-container {
      @apply flex;

      input {
        padding-left: 1.25rem !important;
        @extend .field;
      }

      .react-datepicker__close-icon {
        z-index: 2;
        @apply pr-0 right-12;

        &:after {
          @apply rounded-sm bg-primary-300;
        }
      }
    }
  }

  @at-root .field-wrap {
    &.icon-end {
      .react-datepicker__input-container {
        input {
          @apply pr-16;
        }
      }
    }

    &.icon-start {
      .react-datepicker__input-container {
        input {
          @apply pl-14;
        }
      }
    }
  }
}

.range-date-picker {
  @apply flex p-1 px-2 space-x-4 rounded bg-tableHeader;

  .picker {
    @apply flex items-center relative;

    label {
      @apply mx-1 text-xs text-grey-900;
    }

    input {
      @apply w-full p-1 pr-12 border-0 rounded bg-transparent text-xs text-grey-700;

      &:focus {
        @apply border-0 shadow-none ring-0 outline-none;
      }
    }

    .field-icon {
      svg {
        @apply w-4 h-4;
      }
    }

    .react-datepicker__close-icon {
      @apply pr-0 right-6;

      &:after {
        @apply rounded-sm bg-primary-300;
      }
    }
  }
}

.date-picker-popover {
  @apply pt-0 z-70;

  .react-datepicker {
    @apply pt-3 pb-5 px-4 border-gray-200 rounded shadow-md text-topNav font-body;
  }

  .react-datepicker__navigation {
    @apply w-6 h-6 flex items-center justify-center top-3 p-0 rounded;

    &:hover,
    &:focus,
    &:active {
      @apply bg-primary-200;
    }

    .react-datepicker__navigation-icon {
      font-size: 0;
      @apply top-auto bottom-auto left-auto right-auto;

      &:before {
        @apply w-2 h-2 -top-1 border-t border-r border-primary-600 text-2xl leading-none;
      }
    }

    &.react-datepicker__navigation--previous {
      @apply left-5;

      .react-datepicker__navigation-icon {
        &:before {
          left: -2px;
        }
      }
    }

    &.react-datepicker__navigation--next {
      @apply right-5;

      .react-datepicker__navigation-icon {
        &:before {
          left: -6px;
        }
      }
    }
  }

  .react-datepicker__header {
    @apply mb-3 pt-0 pb-3 border-dashed border-gray-200 bg-transparent;

    .react-datepicker__current-month {
      @apply mb-1 text-topNav text-base font-normal;
    }
  }

  .react-datepicker__month {
    @apply m-0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    @apply w-8 text-topNav leading-8;
  }

  .react-datepicker__day--outside-month {
    @apply opacity-50;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    @apply bg-primary-100 text-primary-700;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    @apply bg-primary-700 text-white;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    @apply bg-primary-700 text-white;
  }
}

.filter-date-picker {
  .react-datepicker-wrapper {
    @apply w-2/4 overflow-hidden;
  }

  input {
    @apply p-0 border-0 bg-transparent text-sm;

    &:focus {
      @apply outline-none border-0 ring-0;
    }
  }
}

.react-select {
  @apply w-full;

  .react-select__control {
    max-height: $fieldHeight;
    @apply border border-primary-200 rounded;

    .react-select__value-container {
      height: $fieldHeight;
      padding: $fieldPadding;

      @apply py-0 pr-0 text-sm;

      .css-b8ldur-Input {
        @apply m-0 p-0;
      }
    }
  }

  .react-select__placeholder {
    @apply text-gray-300 truncate;
  }

  .react-select__indicators {
    margin-left: 1.125rem;

    .react-select__indicator {
      @apply flex items-center justify-center mr-2 p-0 rounded-sm bg-primary-200;

      &.react-select__dropdown-indicator {
        @apply mr-5;
      }
    }
  }

  .react-select__value-container {
    .css-1bwsx9e-Input {
      @apply m-0 p-0 #{!important};
    }
  }

  .react-select__value-container--is-multi {
    @apply flex-nowrap space-x-1;
  }

  .react-select__multi-value {
    @apply m-0 rounded bg-primary-100 text-topNav;
  }

  .react-select__indicator-separator {
    @apply hidden;
  }

  .react-select__menu {
    @apply z-20;
  }

  .react-select__control--is-focused {
    border-color: $primaryColor !important;

    @apply shadow-none ring-1 ring-primary-500 outline-none;

    input {
      &:focus {
        @apply ring-0;
      }
    }
  }

  &.react-select--is-disabled {
    .react-select__control {
      @apply border-grey-400 bg-grey-100;
    }
  }

  &-dense {
    @extend .react-select;
    min-width: 5rem;

    .react-select-dense__control {
      @extend .react-select__control;

      max-height: $fieldHeight;

      .react-select-dense__value-container {
        @extend .react-select__value-container;

        padding: $fieldDensePadding;

        @apply py-1;
      }
    }

    .react-select-dense__placeholder {
      @extend .react-select__placeholder;
    }

    .react-select-dense__indicators {
      @extend .react-select__indicators;

      margin-left: 0.6rem;

      .react-select-dense__indicator {
        @extend .react-select__indicator;

        @apply mr-2;

        &.react-select-dense__dropdown-indicator {
          @extend .react-select__dropdown-indicator;

          @apply mr-2;
        }
      }
    }

    .react-select-dense__value-container--is-multi {
      @extend .react-select__value-container--is-multi;
    }

    .react-select-dense__multi-value {
      @extend .react-select__multi-value;
    }

    .react-select-dense__indicator-separator {
      @extend .react-select__indicator-separator;
    }

    .react-select-dense__menu {
      @extend .react-select__menu;
    }

    .react-select-dense__control--is-focused {
      @extend .react-select__control--is-focused;

      input {
        &:focus {
          @apply ring-0;
        }
      }
    }
  }
}

.react-select__menu,
.phone-country-select__menu {
  min-width: 14.375rem;
  @apply mt-1 mb-0 border border-grey-300 rounded-t-none rounded-b shadow-md #{!important};

  .react-select__menu-list,
  .phone-country-select__menu-list {
    max-height: 320px;
    @apply p-3 divide-y divide-primary-400;

    .react-select__option,
    .phone-country-select__option {
      @apply py-3 px-5 rounded text-grey-800 text-base;

      &.react-select__option--is-selected,
      &.phone-country-select__option--is-selected {
        @apply relative pr-10 bg-transparent text-grey-800;

        &:before,
        &:after {
          content: '';
          position: absolute;
        }

        &:before {
          width: 20px;
          height: 20px;
          right: 10px;
          top: 50%;
          border-radius: 50%;
          background-color: $primaryColor;
          transform: translateY(-50%);
        }

        &:after {
          width: 7px;
          height: 11px;
          right: 20px;
          top: calc(50% - 3px);
          border-bottom: 3px solid #ffffff;
          border-right: 3px solid #ffffff;
          transform: rotate(45deg) translateY(-50%);
        }
      }

      &:hover,
      &.react-select__option--is-focused,
      &.phone-country-select__option--is-focused {
        @apply bg-primary-400 text-white;

        &.react-select__option--is-selected,
        &.phone-country-select__option--is-selected {
          &:before {
            @apply bg-white;
          }

          &:after {
            @apply border-primary-500;
          }
        }
      }
    }
  }
}

.react-select-dense__menu {
  @extend .react-select__menu;
  min-width: 7rem;

  .react-select-dense__menu-list {
    @extend .react-select__menu-list;
    @apply divide-primary-100;

    .react-select-dense__option {
      @extend .react-select__option;

      @apply py-1 px-2 rounded-none;

      &.react-select-dense__option--is-selected {
        @extend .react-select__option--is-selected;
      }

      &:hover,
      &.react-select-dense__option--is-focused {
        @extend .react-select__option--is-focused;

        &.react-select__option--is-selected {
          &:before {
            @apply bg-white;
          }

          &:after {
            @apply border-primary-500;
          }
        }
      }
    }
  }
}

.phone-country-select {
  @apply p-[1px] h-full border-r border-solid border-primary-200;

  .phone-country-select__control {
    @apply h-full border-0 rounded-md;

    .css-g1d714-ValueContainer {
      @apply h-full;
    }
  }

  .phone-country-select__control--is-focused {
    @apply shadow-none outline-none;

    input {
      &:focus {
        @apply ring-0;
      }
    }
  }

  .phone-country-select__indicator-separator {
    @apply hidden;
  }

  .phone-country-select__indicator {
    @apply p-1;
  }

  .phone-country-select__menu {
    width: 150px !important;
  }
}

.switch {
  @apply w-8 h-5 inline-flex items-center flex-shrink-0 relative border border-solid border-primary-500 rounded-full bg-primary-400;

  &:focus {
    @apply outline-none;
  }

  &__handle {
    @apply w-4 h-4 inline-flex items-center justify-center transform bg-white rounded-full translate-x-0.5 transition-transform duration-300;

    @include svgColor($primaryColor);
  }

  &.checked {
    @apply bg-subMenuActive;

    .switch__handle {
      @apply translate-x-3;
    }
  }

  &.green {
    @apply border-green-500 bg-green-100;

    .switch__handle {
      @include svgColor(#00e096);
    }

    &.checked {
      @apply bg-green-500;
    }
  }

  &.blue {
    @apply border-blue-500 bg-blue-100;

    .switch__handle {
      @include svgColor(#0295fa);
    }

    &.checked {
      @apply bg-blue-500;
    }
  }

  &.red {
    @apply border-red-500 bg-red-100;

    .switch__handle {
      @include svgColor(#f0071e);
    }

    &.checked {
      @apply bg-red-500;
    }
  }

  &.yellow {
    @apply border-yellow-500 bg-yellow-100;

    .switch__handle {
      @include svgColor(#fad63f);
    }

    &.checked {
      @apply bg-yellow-500;
    }
  }
}

.floating-form {
  // @apply w-full h-full flex justify-end items-end fixed top-0 left-0 bg-gray-500 bg-opacity-20 z-50;

  @apply w-full h-full max-w-full fixed bottom-0 right-0 py-8 pl-4 pr-1 rounded-md shadow-lg bg-white overflow-auto z-70;

  @include media-breakpoint-up('sm') {
    width: 630px;
    height: 88%;

    @apply bottom-8 right-8 pl-8 pr-3;
  }

  form {
    @apply w-full h-full flex flex-col;
  }
  .floating-form-body {
    height: calc(100% - 3.5rem);

    @include media-breakpoint-up('sm') {
      height: calc(100% - 6rem);
    }
  }
}

.cookie-dialog {
  // @apply w-full h-full flex justify-end items-end fixed top-0 left-0 bg-gray-500 bg-opacity-20 z-50;

  @apply w-full h-full max-w-full fixed bottom-auto right-auto top-0 py-8 pl-4 pr-1 rounded-md shadow-lg bg-white overflow-auto z-70;

  @include media-breakpoint-up('sm') {
    width: 630px;
    max-height: 88%;
    height: auto;
    left: calc(50% - 315px);
    @apply top-16 right-auto pl-8 pr-3;

  }
  .cookie-dialog-body {
    height: 480px;
  }
}

.aside-form {
  min-width: 572px;
  @apply flex-shrink-0;

  .aside-form-container {
    // height: 50rem;
    // height: calc(100vh - 1.5rem - 46px - 76px - 28px - 70px - 48px);
    min-height: 400px;
    height: calc(100vh - 70px - 60px - 84px - 78px - 48px);
    @apply w-full;
  }

  .aside-form-body {
    height: calc(100% - 5rem);
    @apply py-7 pl-7 pr-3 border border-gray-300 rounded-md;
  }
}
