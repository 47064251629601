html,
body {
    @apply bg-layout;
}

.cms-content-wrapper {
    @apply w-full flex-shrink-0;
    transition: 300ms ease;

    @include media-breakpoint-up('xl') {
        &.collapsed {
            @include media-breakpoint-up('xl') {
                width: calc(100% - #{$sideNaveCollapseWidth});
                margin-left: $sideNaveCollapseWidth;
            }
        }

        &.expanded {
            @include media-breakpoint-up('xl') {
                width: calc(100% - #{$sideNavWidth});
                margin-left: $sideNavWidth;
            }
        }
    }
}