@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    /* --- SVG COLORS --- */
    .svg-color {
        &-neutral {
            @include svgColor(inherit);
        }

        &-white {
            @include svgColor(#fff);
        }

        &-black {
            @include svgColor(black);
        }

        &-blue {
            @include svgColor(#0090C9);
        }

        &-blue-dark {
            @include svgColor(#003867);
        }

        &-pink {
            @include svgColor(#EA026E);
        }

        &-purple {
            @include svgColor(#790C68);
        }

        &-green {
            @include svgColor(#2F8D5E);
        }

        &-red {
            @include svgColor(#E32119);
        }

        &-light-red {
            @include svgColor(#F26241);
        }

        &-grey {
            @include svgColor(#5d5d5d);
        }

        &-grey-light {
            @include svgColor(#f1f1f1);
        }

        &-primary {
            @include svgColor($primaryColor);

            &-dark {
                @include svgColor(#6d624c);
            }
        }
    }

    /* --- SVG Size --- */
    .svg-size {
        &-flexible {
            svg {
                width: 100% !important;
                height: auto !important;
            }
        }

        &-fill {
            svg {
                width: 100% !important;
                height: 100% !important;
            }
        }

        &-4xl {
            svg {
                width: 28rem !important;
                height: auto !important;
            }
        }

        &-large {
            svg {
                width: 8rem !important;
                height: auto !important;
            }
        }

        &-medium {
            svg {
                width: 4rem !important;
                height: 4rem !important;
            }
        }

        &-small {
            svg {
                width: 2rem !important;
                height: 2rem !important;
            }
        }

        &-xs {
            svg {
                width: 1rem !important;
                height: 1rem !important;
            }
        }

        &-xss {
            svg {
                width: 0.75rem !important;
                height: 0.75rem !important;
            }
        }

        &-compact {
            svg {
                width: 8rem !important;
                height: 5rem !important;
            }
        }

        &-logo {
            svg {
                width: 28rem !important;
                height: 8rem !important
            }
        }
    }

    .svg-opacity-1 {
        svg {
            opacity: 1;
        }
    }

    .status-bg {
        height: 2.125rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.75rem 1.5625rem;
        color: #172B4D;

        &.success {
            background-color: rgba($color: $successColor, $alpha: 0.25);
            color: darken($color: $successColor, $amount: 20);
        }

        &.warn {
            background-color: rgba($color: $warningColor, $alpha: 0.25);
            color: darken($color:$warningColor, $amount: 20);
        }

        &.danger {
            background-color: rgba($color: $errorColor, $alpha: 0.25);
            color: darken($color: $errorColor, $amount: 20);
        }

        &.red {
            background-color: rgba($color: $lightRed, $alpha: 0.25);
            color: darken($color: $lightRed, $amount: 20);
        }

        &.blue {
            background-color: rgba($color: $blue, $alpha: 0.25);
            color: darken($color: $blue, $amount: 20);
        }

        &.blue-dark {
            background-color: rgba($color: $darkBlue, $alpha: 0.25);
            color: darken($color: $darkBlue, $amount: 20);
        }

        &.pink {
            background-color: rgba($color: $pink, $alpha: 0.25);
            color: darken($color: $pink, $amount: 20);
        }

        &.orange {
            background-color: rgba($color: $orange, $alpha: 0.25);
            color: darken($color: $orange, $amount: 20);
        }

        &.purple {
            background-color: rgba($color: $purple, $alpha: 0.25);
            color: darken($color: $purple, $amount: 20);
        }

        &.disabled {
            background-color: #E3E3E3;
            color: #8D8D8D;
        }

        &.compact {
            min-width: initial;
            height: auto;
            padding: 7px 8px;
            line-height: 1;
        }
    }

    .text-ellipsis {
        max-width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.line-2 {
            @include multiLineEllipsis(2);
        }

        &.line-3 {
            @include multiLineEllipsis(3);
        }
    }

    .badge {
        @apply inline-flex justify-center items-center absolute -top-1 right-0 rounded-full h-3 w-3 bg-red-500 text-white text-xs z-1;

        &:after {
            content: "";
            @apply inline-flex absolute h-full w-full rounded-full bg-red-500 opacity-75 -z-1;
        }
    }

    .backdrop {
        @apply w-full h-full fixed left-0 top-0 z-60;
    }

    .animate-delay {
        @for $i from 0 through 10 {
            &-#{$i} {
                animation-delay:calc(#{$i} * 100ms)
            }
        }
    }

    .scroll-hidden {
        overflow: hidden !important;

        &-padding {
            overflow: hidden !important;
            padding-right: 15px !important;
        }
    }
}

@layer components {
    .folder-ui {
        height: 166px;
        @apply max-h-full w-full flex items-end relative z-1;

        &:before {
            height: calc(100% - 1rem);
            content: "";
            @apply w-full absolute bottom-0 left-0 border-8 border-solid border-white rounded-2xl bg-layout -z-1;
        }

        .wrapper {
            height: calc(100% - 2.25rem);
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
            @apply w-full relative p-3 rounded-2xl rounded-tl-none bg-white z-1;

            &:before {
                width: 33%;
                content: "";
                box-shadow: -2px -2px 10px -4px rgba($color: #000000, $alpha: 0.1);
                @apply h-9 absolute -top-9 left-0 rounded-t-2xl bg-white;
            }

            &:after {
                width: 30px;
                content: "";
                box-shadow: 5px -2px 10px -4px rgba($color: #000000, $alpha: 0.1);
                transform: skewX(45deg);

                @apply h-8 absolute left-1/4 -top-8 bg-white rounded-tr;
            }
        }

        &.selected {
            &:before {
                border-color: #E1DDCF;
            }

            .wrapper {
                @apply bg-sideNav;

                &:before,
                &:after {
                    @apply bg-sideNav;
                }
            }
        }
    }
}