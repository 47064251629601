@import './abstracts/variables';
@import './abstracts/mixins';
@import './abstracts/animation.scss';

// Vendor Css Here
@import 'animate.css/animate.min.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'react-tabs/style/react-tabs.css';
@import '@splidejs/splide/dist/css/themes/splide-default.min.css';
@import './tailwind';

@import './base/typography';
@import './base/breakpoint';

/* Layout import */
@import './layouts/layout.scss';
@import './layouts/sidenav.scss';
@import './layouts/topnav.scss';
@import './layouts/auth.scss';

/* Component import */
@import './components//perfect-scrollbar.scss';
@import './components/buttons.scss';
@import './components/form.scss';
@import './components/table.scss';
@import './components/tab.scss';
@import './components/toast.scss';
@import './components/file-viewer.scss';

/* Overwrite */
@import './overwrite/helper.scss';
@import './overwrite/overwrite.scss';

// html {
//     overflow-y: scroll;
// }
