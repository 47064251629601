.field-table {
    @extend .field;

    @apply border-gray-400;
}

.tbl-filter {
    max-height: $fieldHeight;
    @apply flex items-center relative border border-gray-400 rounded divide-x divide-gray-200;

    label {
        @apply flex-shrink-0 text-gray-500 text-xs leading-4 px-3;
    }
}

table {
    .field-group {
        @apply mb-0;

        .field-wrap {
            +span {
                @apply hidden;
            }
        }

        .field {

            &:disabled,
            &:read-only {
                @apply h-auto p-0 border-0 bg-transparent;
            }
        }

        .react-select {
            &.react-select--is-disabled {
                .react-select__control {
                    @apply border-0 bg-transparent;

                    .react-select__value-container {
                        @apply p-0;

                        .react-select__single-value {
                            @apply text-black;
                        }
                    }
                }

                .react-select__indicators {
                    @apply hidden;
                }
            }
        }

        &.date-time-picker {
            input {

                &:disabled,
                &:read-only {
                    @apply h-auto p-0 border-0 bg-transparent ring-0 #{!important};
                }
            }
        }
    }
}

.tbl-header {
    @apply bg-tableHeader text-gray-500 text-base font-normal text-left;

    &:first-child {
        @apply rounded-bl rounded-tl;
    }
    &:last-child{
        @apply rounded-tr rounded-br;
    }
}

.pagination {
    @apply flex flex-wrap items-center p-0 space-x-3;

    @include media-breakpoint-up('sm') {
        @apply ml-8;
    }

    button {
        @apply p-1 text-gray-400 text-base;

        &:hover {
            @apply text-primary-800;
        }

        &:focus {
            @apply outline-none
        }

        &.active {
            @apply text-primary-500;
        }

        &.pagination-nav {
            @apply w-8 h-8 flex justify-center items-center p-0 rounded-md bg-primary-100;

            &:not(:disabled) {
                &:hover {
                    @apply shadow-md ring-2 ring-primary-600;
                }
            }
        }
    }
}

.edit-highlight-selection {
    width: 4.1rem;
    height: 3.5rem;
    left: calc(100% - 1px);
    @apply block absolute border-t border-b border-solid border-gray-300 bg-white transition-all;
}