$max: 500;
$offset: 50;
$unit: 'px'; // Feel free to change the unit.@mixin list-loop($className, $styleName) {
$i: 0;

@mixin list-loop($className, $styleName){
  $i: 0;
  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;       
  }
}

@include list-loop('.min-width-', 'min-width');