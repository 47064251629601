$breakpoints: ('sm': 640px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xxl':1536px) !default;

@mixin media-breakpoint-up($width) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $width) {

    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $width)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$width}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin media-breakpoint-down($width) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $width) {

    // Prints a media query based on the value
    @media (max-width: (map-get($breakpoints, $width) - 1px)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$width}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}