$primaryColor: #b5a47e;

$errorColor: #B60F0F;
$warningColor: #FFA85D;
$successColor: #18C35A;

$blue:#11B1E7;
$darkBlue:#16507F;
$purple: #790C68;
$pink:#EA026E;
$lightRed:#EC2023;
$orange:#F26241;

$toolbarHeight: 4.375rem;
$mobileToolbarHeight: 4.8rem;

$sideNavWidth: 351px;
$sideNaveCollapseWidth: 100px;

$fieldHeight: 3.125rem;
$fieldPadding: 1.1875rem 1.125rem;
$fieldDensePadding: 0.5rem 0.6rem;