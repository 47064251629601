.Toastify {
    &__toast-container {
        @apply max-w-[480px] min-w-[320px] w-auto;

        @include media-breakpoint-down('sm') {
            max-width: 100%;
        }
    }

    &__toast {
        @apply flex p-0 bg-white text-black;

        .icon {
            @apply flex items-center py-0 px-3;

            @include svgColor(#fff);
        }

        &--info {
            .icon {
                @apply bg-blue-600;
            }
        }

        &--success {
            .icon {
                background-color: $successColor;
            }
        }

        &--warning {
            .icon {
                background-color: $warningColor;
            }
        }

        &--error {
            .icon {
                background-color: $errorColor;

            }
        }
    }

    &__toast-body {
        @apply flex m-0 p-0;

        h5 {
            @apply py-3 px-6;

            span {
                @apply text-gray-400;
            }
        }
    }

    &__close-button {
        @apply w-6 h-6 flex items-center justify-center m-3 rounded;

        &:hover {
            @apply bg-gray-200;
        }

        svg {
            width: 2rem;
            height: 2rem;
        }

        @include svgColor(#aaa);
    }
}