.line-tabs {
    &__list {
        @apply mb-3 border-b border-solid border-gray-300;

        li {
            @apply border-0 border-b border-solid border-transparent bg-transparent text-gray-400 text-lg uppercase;

            &:focus {
                box-shadow: none;
                border-color: transparent;

                &:after {
                    display: none
                }
            }

            &.react-tabs__tab--selected {
                @apply border-primary-700 text-primary-700;
            }

        }
    }
}