.text-focus-in {
    animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.tracking-in-expand {
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1.2s both;
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes text-focus-in {
    0% {
        transform: translateX(39px);
        filter: blur(12px);
        opacity: 0;
    }

    30% {
        transform: translateX(39px);
        filter: blur(0px);
        opacity: 1;
    }

    65% {
        transform: translateX(39px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        transform: translateX(0px);
        filter: blur(0px);
        opacity: 1;
    }
}