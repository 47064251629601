.cms-side-nav {
    width: $sideNavWidth;
    transition: 300ms ease;
    @apply h-[100%] flex-shrink-0 bg-sideNav fixed z-50;

    .cms-menu-item {
        padding: 0 0.96875rem;
        @apply py-2;

        >a {
            @apply rounded-md text-sideNav;
            @include svgColor(#636365);

            &.active {
                @apply bg-subMenuActive;
                @include svgColor(#ffffff);

                .text {
                    @apply text-sideNavActive;
                }
            }
        }

        .icon {
            @apply mr-4;
        }
    }

    .cms-submenu-item {

        li {
            &:first-child {
                a {
                    @apply rounded-t-md
                }
            }

            &:last-child {
                a {
                    @apply rounded-b-md
                }
            }
        }

        a {
            @apply flex py-3 pl-[calc(1.5rem+1rem+1.4rem)] pr-6 text-sideNav transition-all;
            @include svgColor(#636365);

            &.active {
                @apply text-primary-600;
                @include svgColor(#7A6A49)
            }
        }

        @include media-breakpoint-up('xl') {
            &.hover-menu {
                left: $sideNavWidth;

                @apply max-w-[260px] min-w-[220px] block fixed -mt-12 shadow-lg;

                &:before {
                    content: "";
                    border-color: transparent #7A6A49 transparent transparent;
                    @apply absolute top-3 -left-2 border-8 border-l-0 border-solid;
                }

                li {
                    a {
                        @apply px-5 bg-hoverSubMenu text-hoverSubMenu;

                        &:hover {
                            @apply bg-primary-700;
                        }

                        .icon {
                            @apply mr-4;
                        }

                        @include svgColor(#ffffff);

                        &.active {
                            @apply bg-hoverSubMenuActive text-hoverSubMenu;
                        }
                    }
                }
            }
        }
    }

    .cms-sidenav-foot {
        @apply mt-auto p-8 px-8 text-primary-600 text-sm;
    }

    &.expanded {
        @include media-breakpoint-up('xl') {
            .cms-submenu-item {
                &.hover-menu {
                    left: $sideNavWidth;
                }
            }
        }
    }

    &.collapsed {
        @include media-breakpoint-up('xl') {
            width: $sideNaveCollapseWidth;

            .cms-menu-item {
                @apply flex justify-center;

                >a {
                    width: 2.8125rem;
                    height: 2.8125rem;
                    @apply justify-center mb-3 p-1;

                    span {
                        &:not(.icon) {
                            @apply hidden
                        }
                    }

                    &.active {
                        @apply border-0;
                    }
                }

                .icon {
                    @apply m-0;
                }
            }

            .cms-submenu-item {
                @apply hidden -mt-16;

                &.hover-menu {
                    left: $sideNaveCollapseWidth;
                    @apply block mt-0;
                }
            }

            .cms-sidenav-foot {
                @apply hidden;
            }
        }
    }

    @include media-breakpoint-down('xl') {
        &.mobile-menu-active {
            @apply flex;
        }
    }
}