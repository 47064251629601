#proxy-renderer {
    min-height: 800px;

    .react-pdf__Page__annotations {
        @apply hidden;
    }

    #pdf-controls {
        @apply px-10 shadow-none space-x-4;

        >a,
        button {
            @apply text-white;

            svg {
                width: 16px;
                fill: #fff;

                * {
                    &:not([stroke-width]) {
                        &:not([fill="none"]) {
                            fill: #fff;
                        }
                    }

                    &[stroke-width] {
                        stroke: #fff;
                    }

                    rect{
                        display: none;
                    }
                }
            }
        }
    }
}