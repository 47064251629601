.space-manager-container {
  width: calc(100% - 580px - 66px);
}

.fix-table-col-max-width {
  table > {
    thead > {
      tr > {
        th {
          max-width: 320px;
          word-wrap: break-word;
        }
      }
    }
    tbody > {
      tr > {
        td {
          max-width: 320px;
          word-wrap: break-word;
        }
      }
    }
  }
}

.tbl-customize-sm {
  .tbl-header {
    font-weight: bold;
  }
  .tbl-header,
  tbody tr td {
    font-size: 14px;
    max-width: 130px;
  }
}

@media (max-width: 640px) {
  .tbl-customize-sm {
    .tbl-header,
    tbody tr td {
      min-width: 120px;
      max-width: 320px;
      width: auto !important;
    }
  }
}

.fix-readonly-input-display {
  .field:disabled {
    min-width: 160px;
    width: 100%;
  }
}

.empty-wrapper {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}

.empty-image {
  height: 100px;
  margin-bottom: 8px;
  svg {
    height: 100%;
    margin: auto;
  }

  .ellipse {
    fill: rgba(122, 106, 73, 0.1);
    fill-opacity: 0.8;
  }

  .path-1 {
    fill: rgba(122, 106, 73, 0.5);
  }

  .path-2 {
    fill: rgba(122, 106, 73, 1);
  }

  .path-3 {
    fill: #f5f5f7;
  }

  .path-4 {
    fill: rgba(122, 106, 73, 1);
  }

  .path-5 {
    fill: rgba(122, 106, 73, 1);
  }

  .g {
    fill: #fff;
  }
}

.cms-side-nav {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  .cms-menu-item > a span.text {
    max-height: 24px;
    overflow: hidden;
  }
  &.expanded,
  &.collapsed {
    transition-duration: 0.3s;
    transition-property: width;
  }
}
