// Perfect Scrollbar
.rail-visible {

    .ps__rail-y,
    .ps__rail-x {
        @apply rounded-md opacity-100;
    }
}

.ps__rail-y {
    width: 10px;
    background-color: #EBEBEB !important;

    &:hover,
    &.ps--clicking {
        width: 15px;
        background-color: #EBEBEB !important;

        .ps__thumb-y {
            background-color: #917D5D;
        }
    }
}

.ps__rail-x {
    height: 10px;
    background-color: #EBEBEB !important;

    &:hover,
    &.ps--clicking {
        height: 15px;
        background-color: #EBEBEB !important;

        .ps__thumb-x {
            background-color: #917D5D;
        }
    }
}

.ps__thumb-y,
.ps__thumb-x {
    background-color: #917D5D;
}